<template>
  <div class="box">
    <div class="box_container">
      <div style="height:10px;"></div>
      <!-- 下拉框 -->
      <div class="head" ref="head" style="flex-wrap: wrap;">
        <div style="display: flex;">
          <div class="fhText mouse" @click="backbtn">{{ $t('message.refund') }}</div>
          <div style="margin-left: 3vw;">
            <div class="flex" style="margin-bottom:10px;">
              <div class="flex head_dropDown">
                <span>{{ $t('message.Outofboundstype') }}</span>
                <el-select class="head_select" style="width: 120px;" v-model="taransboundaryId" filterable
                  :placeholder="$t('message.Pleaseselectausername')">
                  <el-option v-for="item in taransboundaryType" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="flex head_dropDown">
                <span>{{ $t('message.userName') }}</span>
                <el-select class="head_select" v-model="userId" filterable
                  :placeholder="$t('message.Pleaseselectausername')">
                  <el-option v-for="item in user" :key="item.User_ID" :label="item.User_Name" :value="item.User_ID">
                  </el-option>
                </el-select>
              </div>
              <div class="flex head_dropDown">
                <span>{{ $t('message.fencename') }}</span>
                <el-select class="head_select" v-model="efid" filterable
                  :placeholder="$t('message.Pleaseenterthefencename')">
                  <el-option v-for="item in fenceName" :key="item.efId" :label="item.efName" :value="item.efId">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="flex">
              <div class="flex head_dropDown">
                <span>{{ $t('message.Triggermode') }}</span>
                <el-select class="head_select" v-model="triggerType" filterable
                  :placeholder="$t('message.Pleaseselecttriggermethod')">
                  <el-option v-for="item in triggerMethod" :key="item.value" :label="item.text" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="flex head_dropDown">
                <span>{{ $t('message.Crossborderdate') }}</span>
                <el-date-picker v-model="valueStart" type="datetimerange" range-separator="~"
                  :picker-options="pickerOptions" :start-placeholder="$t('message.startrange')"
                  :end-placeholder="$t('message.endrange')" align="right">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex;">
          <div class="head_search mouse" @click="search()">
            {{ $t('message.search') }}
          </div>
        </div>
      </div>
      <!-- 下拉结束 -->

      <!-- 表格 -->
      <div class="table" :style="tableHeight">
        <history-table :bool="bool" v-if="bool" :tableData="tableData" :tableHead="tableHead" ref="history">
        </history-table>
      </div>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination class="pagination_bottom" :current-page="paging.pageNo" background :page-sizes="[20, 40, 60, 80]"
          layout="total,prev, pager, next,jumper,slot, sizes" @size-change="sizeChange" @prev-click="prevClick"
          @next-click="nextClick" @current-change="currentChange" :total="paging.total">
          <span class="confirbtn" @click="comfir()">{{ $t('message.confirm') }}</span>
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script src=""></script>
<script>
  import { queryUserByEnclosure} from "@/administration/electronicsEnclosure.js";
  import historyTable from "@/components/history/table.vue";
  import beasconfig from "@/api/beasconfig";
  const enclosureUrlapi = beasconfig.enclosureUrlapi;
  import axios from "axios";
  export default {
    components: {
      historyTable
    },
    // props: {
  
    // },
    data() {
      return {
        pickerOptions: {
          // disabledDate(time) {
          //   let curDate = new Date().getTime();
          //   let three = 90 * 24 * 3600 * 1000;
          //   let threeMonths = curDate - three;
          //   return time.getTime() > Date.now() || time.getTime() < threeMonths;
          // }
          disabledDate (date) {
            return date.getTime() >= Date.now()
          }
        },
        valueStart: [], //开始时间
        taransboundaryType:[
          {
            id:1,
            name: this.$t('message.currentrecord')
          },
          {
            id:2,
            name: this.$t('message.historyrecord')
          }
        ],
        taransboundaryId:1,
        user: [
          //用户
        ],
        userId: "", //用户id
        triggerMethod:[
          {
            value:-1,
            text:this.$t('message.Pleaseselecttriggermethod')
          },
          {
            value:0,
            text:this.$t('message.Forbidden')
          },
          {
            value:1,
            text:this.$t('message.Noentry')
          }
        ],
        triggerType:"",
        fenceName:[],
        efid:'',
        currentPage4: 4,
        paging: {
          total: 0,
          currentPage: 1,
          pageNo: 1,
          pageSize: 20
        },
        sos: {
          clUser: "", //发送人
          fsUser: "", //处理人
          fsStartTime: "", //发送开始时间
          clStartTime: "" //处理结束时间
        },
        // sos
        userInfo: {}, //用户信息
        chooselist: [], //选中数据
        tableData: [], //table数据
        tableHead: [
          {
            lable: this.$t('message.fencename'),
            prop: "efName"
          },
          {
            lable: this.$t('message.userName'),
            prop: "userName"
          },
          {
            lable: "IMEI",
            prop: "userAccount"
          },
          {
            lable: this.$t('message.Triggermode'),
            prop: "efType"
          },
          {
            lable: this.$t('message.Validperiod'),
            prop: "effectiveTimes"
          },
          {
            lable: this.$t('message.Triggertime'),
            prop: "alarmTimestamp"
          }
        ], //表头数据
        bool: true,
        historyIndex: 0,
        routeName:null,
        showbtn:false,
        tableHeight:''
      };
    },
    created() {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.getFenceMember();
      this.getQueryUserByEnclosure();
      this.refresh();
    },
    beforeCreate: function() {},
    beforeDestroy() {
     
    },
    beforeRouteEnter(to, from, next) {   //监听页面
        next(vm => {
            if(from.name != 'TransboundaryRecord' && from.name != null){
              vm.routeName = from.path;
            }
        })
    },
    mounted() {
      let height= this.$refs.head.offsetHeight;  //100
      this.tableHeight = `height:calc(100vh - 155px - ${height}px)`;
      //  this.$refs.myVideo.addEventListener("play", () => {
      //     console.log("video is playing");
      // });
      // // 监听视频暂停
      // this.$refs.myVideo.addEventListener("pause", () => {
      //     console.log("video is stop");
      // });
    },
    methods: {
      /*返回群组列表*/
      backbtn() {
        if(this.showbtn){
          this.showbtn = false;
          this.userId = '';
          this.efid = '';
          this.triggerType = '';
          this.valueStart = [];
          this.refresh();
        }else{
          if(this.$route.name == 'TransboundaryRecord'){
            this.$router.push(`/enclosure/googleMap`);
          }else{
            this.$router.go(-1);
          }
        }
      },
      getBoundsList() {   /*历史越界列表*/
        let startTime = "";
        let endTime = "";
        if (this.valueStart != "" && this.valueStart != null) {
          //开始时间
           startTime =
            this.$moment(this.valueStart[0]).valueOf();
           endTime =
            this.$moment(this.valueStart[1]).valueOf();
        }else{
          startTime = "";
          endTime = "";
        }  
        let params = {
          companyId: parseInt(this.userInfo.User_CompanyID),
          ddyId:parseInt(this.userInfo.User_ID),
          userId:this.userId,
          pageNum:this.paging.pageNo,
          pageSize:this.paging.pageSize,
          efId : this.efid,
          efType : this.triggerType,
          startTime:startTime,
          endTime:endTime
        };
        axios.get(`${enclosureUrlapi}/alarm/history`,{params})
        // axios.get(`/liang/electronicFence/list`,{params})
          .then(res => {
            // console.log('围栏列表', res);
            this.tableData = res.data.data.rows;
            this.paging.total = res.data.data.total;
          }).catch(console.error)
      },
      getTransboundList() {   /*当前记录列表*/
        let startTime = "";
        let endTime = "";
        if (this.valueStart != "" && this.valueStart != null) {
          //开始时间
           startTime =
            this.$moment(this.valueStart[0]).valueOf();
           endTime =
            this.$moment(this.valueStart[1]).valueOf();
        }else{
          startTime = "";
          endTime = "";
        }
        let params = {
          companyId: parseInt(this.userInfo.User_CompanyID),
          ddyId:parseInt(this.userInfo.User_ID),
          userId:this.userId,
          pageNum:this.paging.pageNo,
          pageSize:this.paging.pageSize,
          efId : this.efid,
          efType : this.triggerType,
          startTime:startTime,
          endTime:endTime
        };
        axios.get(`${enclosureUrlapi}/alarm/transbound`,{params})
        // axios.get(`/liang/electronicFence/list`,{params})
          .then(res => {
            // console.log('围栏列表', res);
            this.tableData = res.data.data.rows;
            this.paging.total = res.data.data.total;
          }).catch(console.error)
      },
      getQueryUserByEnclosure() {   //查询非围栏成员
            let data = {
                oId: this.userInfo.User_CompanyID
              }
            queryUserByEnclosure(data).then(res => {
                // console.log(res)
                if (res.data.code == 200) {
                    this.user = res.data.data;
                    this.user.unshift({
                      User_ID: -1,
                      User_Name: this.$t('message.Pleaseselectauser')
                    });
                } else {
                    this.$MessageWarning(res.data.msg);
                }
            })
        },
      getFenceMember(){
        let params = {
          companyId: parseInt(this.userInfo.User_CompanyID),
          ddyId:parseInt(this.userInfo.User_ID),
        };
        axios.get(`${enclosureUrlapi}/electronicFence/getEfAll`,{params})
        // axios.get(`/liang/electronicFence/getEfAll`,{params})
          .then(res => {
            // console.log('围栏列表', res);
            this.fenceName = res.data.data;
            this.fenceName.unshift({
              'efId': -1,
              'efName': this.$t('message.Pleaseselectafencename')
            })
          }).catch(console.error)
      },
      search() {
        //搜索
        if (this.userId != '' || this.efid != '' || this.triggerType !== '' || this.valueStart != "" && this.valueStart != null && this.valueStart.length > 0) {
          this.showbtn = true;
        } else {
          this.showbtn = false;
        }
        this.paging.pageNo = 1;
        this.refresh();
      },
      refresh() {
        //刷新
        if(this.taransboundaryId == 1){
          this.getTransboundList();
        }else{
          this.getBoundsList();
        }
      },
      comfir() {
        //确定按钮
        this.refresh();
      },
      sizeChange(pageSize) {
        //当前页数据发生改变时
        this.paging.pageSize = pageSize;
        this.refresh();
      },
      prevClick(pageNum) {
        //前进按钮
        this.paging.pageNo = pageNum;
        this.refresh();
      },
      nextClick(pageNum) {
        //退后按钮
        this.paging.pageNo = pageNum;
        this.refresh();
      },
      currentChange(pageNum) {
        //当前页发生改变时
        this.paging.pageNo = pageNum;
        this.refresh();
      }
    },
    watch: {
      //监听事件
      triggerType(val) {
        if (val == -1) {
          this.triggerType = "";
        }
      },
      userId(val) {
        if (val == -1) {
          this.userId = "";
        }
      },
      efid(val) {
        if (val == -1) {
          this.efid = ""
        }
      },
      taransboundaryId(id) {
          this.paging.pageNo = 0;
          this.paging.pageSize = 20;
          this.showbtn = false;
          this.userId = '';
          this.efid = '';
          this.triggerType = '';
          this.valueStart = [];
          this.tableData = [];
          this.bool = false;
          setTimeout(()=>{
            this.bool = true;
          },200)
        if (id == 1) {
          this.tableHead.splice(this.tableHead.length - 1, 1);
          this.getTransboundList();
        }else{
          this.tableHead.push({
            lable: this.$t('message.Releasetime'),
            prop: "relieveAlarmTimestamp"
          })
          this.getBoundsList();
        }
      }
    }
  };
  </script>

<style scoped lang="less">
.box {
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #ffffff;

  &_container {
    width: calc(100% - 20px);
    height: 100%;
    margin: auto;

    .head {
      width: 100%;
      // padding: 10px 0px;
      background-color: #f0f0f0;
      border-radius: 5px;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 15px;
      color: #5a5655;

      &>div {
        padding: 10px 0px;
      }

      &_container {
        flex: 1;
        margin-left: 10%;
      }

      &_dropDown {
        font-size: 15px;
        color: #5a5655;
        margin-right: 20px;

        span {
          margin-right: 5px;
        }
      }

      &_dropDown:last-child {
        margin-right: 0px;
      }

      &_select {
        width: 160px;
        height: 40px;
      }

      &_picker {
        width: 280px !important;
        text-align: center;
      }

      &_search {
        width: 70px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        // background: url(/img/leftBg1.81e6fd48.png);
        background-color: #4d4d4d;
        background-size: 100% 100%;
        border-radius: 5px;
        color: #ffffff !important;
        margin-left: 15px;
        margin-right: 15px;
        // margin-top: 10px;
      }

      &_but {
        width: 110px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #327677;
        border-radius: 3px;
        color: #ffffff !important;
        margin-right: 5px;
      }
    }

    .table_one {
      height: calc(100vh - 73px - 145px);
    }

    .table_tow {
      height: calc(100% - 235px);
    }

    .table {
      width: 100%;
      background-color: #f0f0f0;
      overflow: auto;
      color: #000000;

      &_box {
        width: 100%;
        text-align: center;
      }

      &_but {
        color: var(--main-color);
        margin-right: 10px;
      }
    }

    .pagination {
      width: 100%;
      // height: 80px;
      text-align: left;
      position: relative;
      background-color: #ffffff;

      /deep/.el-pagination__jump {
        margin-left: 0;
      }

      .tip-sos {
        text-align: center;
        color: #b2b2b2;
        height: 30px;
        line-height: 30px;
      }

      &_bottom {
        padding: 15px 0px;
        // position: absolute;
        // bottom: 0px;
        // left: 0px;
      }

      .confirbtn {
        background-color: #5a5657;
        padding: 0 6px;
        color: #ffffff;
        margin-left: 4px;
        cursor: pointer;
        border-radius: 2px;
        // line-height: 14px;
      }
    }
  }
}

.fhText {
  width: 70px;
  height: 45px;
  line-height: 45px;
  // height: min-content;
  // padding: 12px 20px;
  color: #fff;
  background-color: #4d4d4d;
  border-radius: 5px;
  margin-left: 15px;
  font-size: 15px;
  margin-right: 20px;
}

.video-box {
  width: 100%;
  height: 480px;
}

vidio {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg_one {
  color: #ffffff;
  background: url("../../assets/history/select.png");
}

.bg_tow {
  color: #000000;
  background: url("../../assets/history/select1.png");
}

/deep/.el-input__icon {
  display: block !important;
}

.flex {
  display: flex;
  align-items: center;
}
</style>